@use "../../scss/colors";
@use "../../scss/mixins";
@use "../../scss/font";

.PageTitleSection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 2.5rem 2.5rem 1.5rem;

  @include mixins.less_than_desktop_inclusive {
    padding: 1.5rem 1rem;
  }

  &--header {
    display: flex;
    place-items: center flex-start;
    flex-direction: row;
    align-content: center;
    gap: 1rem;
  }

  &--title {
    display: flex;
    font-size: 2rem;
    font-weight: font.$weight--bold;

    @include mixins.md_l {
      font-size: 2rem;
    }
  }

  &--timestamp {
    color: colors.$dark_grey_on_white;
    display: flex;
    align-items: center;

    @include mixins.less_than_desktop_inclusive {
      display: none;
    }
  }
}
